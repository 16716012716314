import { Button, DatePicker, Form, Modal, Spin } from 'antd';
import { SUCCESSFULLY_EDIT_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { releaseFishGroup } from 'redux/thunks';

const ReleaseGroupForm = ({ open = false, fishGroup, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.fishGroup.loading);

    const handleReleaseFishGroup = async (req) => {
        req.actionDate.startOf('day');

        try {
            await dispatch(
                releaseFishGroup({
                    id: fishGroup.id,
                    item: req
                })
            ).unwrap();

            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onReload();
            handleCancel();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={t('fishGroup.releaseGroupForm.title')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        onClick={form.submit}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {t('fishGroup.releaseGroupForm.release')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={handleReleaseFishGroup}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('fishGroup.actionDate.title')}
                        name="actionDate"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.actionDate.required')
                            }
                        ]}
                    >
                        <DatePicker placeholder={t('fishGroup.actionDate.placeholder')} />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

ReleaseGroupForm.propTypes = {
    open: PropTypes.bool,
    fishGroup: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default ReleaseGroupForm;
