import { useMsal } from '@azure/msal-react';
import { GLOBAL_ADMINISTRATOR } from 'common/constants';
import { NotAllowed, TenantHandling } from 'pages';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const TenantRoute = ({ children, isNeedTenant = false }) => {
    const { accounts } = useMsal();
    const { data: tenantInfo = {} } = useSelector((s) => s.headerTenant);

    if (accounts.length && !accounts[0].idTokenClaims?.roles?.includes(GLOBAL_ADMINISTRATOR))
        return <NotAllowed />;

    if (isNeedTenant && !tenantInfo.currentTenantId) return <TenantHandling />;

    return <>{children}</>;
};

TenantRoute.propTypes = {
    path: PropTypes.string,
    children: PropTypes.node
};

export default TenantRoute;
