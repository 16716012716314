import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { TenantRoute, routes } from 'routes';
import './index.scss';

const App = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
    }, [pathname]);

    const renderRoute = (route, key, renderRoute = () => {}) => (
        <Route
            path={route.path}
            key={key}
            element={<TenantRoute isNeedTenant={route.isNeedTenant}>{route.element}</TenantRoute>}
        >
            {route.children?.map((child, index) =>
                renderRoute(child, `${key}_${index}`, renderRoute)
            )}
        </Route>
    );

    return <Routes>{routes.map((route, index) => renderRoute(route, index, renderRoute))}</Routes>;
};

export default App;
