import {
    CAMERA_PAGE,
    COMPANY_PAGE,
    DASHBOARD_PAGE,
    FISHERY_BOAT_PAGE,
    FISH_GROUP_PAGE,
    FORBIDDEN_PAGE,
    NOT_ALLOWED_PAGE,
    NOT_FOUND_PAGE,
    PEN_PAGE,
    PROCESS_PAGE,
    PROFILE_PAGE,
    SERVICE_BOAT_PAGE,
    SERVICE_PAGE,
    SESSION_EXPIRED_PAGE,
    SITE_PAGE,
    TENANT_HANDLING_PAGE,
    TENANT_PAGE,
    USER_PAGE
} from 'common/constants';
import Layout from 'common/layouts';
import {
    Camera,
    Company,
    DashBoard,
    Engine,
    FishGroup,
    Forbidden,
    NotAllowed,
    NotFound,
    Process,
    Profile,
    ServiceBoat,
    SessionExpired,
    Site,
    Tenant,
    TenantHandling,
    User
} from 'pages';
import FisheryBoat from 'pages/fishery-boat';
import Pen from 'pages/pen';
import Service from 'pages/service';

const routes = [
    {
        path: TENANT_HANDLING_PAGE,
        name: 'Tenant handling',
        element: <TenantHandling />
    },
    {
        path: FORBIDDEN_PAGE,
        name: 'Forbidden',
        element: <Forbidden />
    },
    {
        path: NOT_ALLOWED_PAGE,
        name: 'Not Allowed',
        element: <NotAllowed />
    },
    {
        path: SESSION_EXPIRED_PAGE,
        name: 'Session Expired',
        element: <SessionExpired />
    },
    {
        path: '/',
        element: <Layout />,
        isNeedTenant: true,
        children: [
            {
                path: DASHBOARD_PAGE,
                name: 'Dashboard',
                element: <DashBoard />,
                isNeedTenant: true
            },
            {
                path: TENANT_PAGE,
                name: 'Tenant',
                element: <Tenant />,
                isNeedTenant: true
            },
            {
                path: USER_PAGE,
                name: 'User',
                element: <User />,
                isNeedTenant: true
            },
            {
                path: COMPANY_PAGE,
                name: 'Company',
                element: <Company />,
                isNeedTenant: true
            },
            {
                path: SITE_PAGE,
                name: 'Site',
                element: <Site />,
                isNeedTenant: true
            },
            {
                path: SERVICE_BOAT_PAGE,
                name: 'Service boat',
                element: <ServiceBoat />,
                isNeedTenant: true
            },
            {
                path: SERVICE_PAGE,
                name: 'service',
                element: <Service />,
                isNeedTenant: true
            },
            {
                path: PROFILE_PAGE,
                name: 'Profile',
                element: <Profile />,
                isNeedTenant: true
            },
            {
                path: PEN_PAGE,
                name: 'pen',
                element: <Pen />,
                isNeedTenant: true
            },
            {
                path: '/engine',
                name: 'engine',
                element: <Engine />,
                isNeedTenant: true
            },
            {
                path: FISHERY_BOAT_PAGE,
                name: 'Fishery Boat',
                element: <FisheryBoat />,
                isNeedTenant: true
            },
            {
                path: FISH_GROUP_PAGE,
                name: 'FishGroup',
                element: <FishGroup />,
                isNeedTenant: true
            },
            {
                path: CAMERA_PAGE,
                name: 'Camera',
                element: <Camera />,
                isNeedTenant: true
            },
            {
                path: PROCESS_PAGE,
                name: 'Process',
                element: <Process />,
                isNeedTenant: true
            },
            {
                path: NOT_FOUND_PAGE,
                element: <NotFound />
            }
        ]
    },
    {
        path: '*',
        element: <NotFound />
    }
];

export default routes;
