import { SyncOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Spin } from 'antd';
import { FDSelection } from 'common/components';
import {
    PRODUCTION_FISH_NAMES,
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_EDIT_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, generateRandomFishGroupId } from 'common/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addFishGroup, updateFishGroup } from 'redux/thunks';
import XRegExp from 'xregexp';

const UpsertGroupForm = ({ open = false, fishGroup, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.fishGroup.loading);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const pensSelection = useSelector((s) => s.penSelection.data);

    const localityNumber = Form.useWatch('localityNumber', form);

    const penNumber = useRef(null);
    const organizationId = useRef(null);

    const isUpdateForm = !!fishGroup;

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue({
                ...fishGroup,
                actionDate: moment(fishGroup.actionDate),
                penId: pensSelection.find(
                    (item) =>
                        item.penNumber === fishGroup.penNumber &&
                        item.localityNumber === fishGroup.localityNumber
                )?.id
            });
        } else {
            form.resetFields();
        }
    }, [fishGroup]);

    const upsertFishGroup = async (req) => {
        if (isUpdateForm) {
            handleUpdateFishGroup(req);
        } else {
            handleCreateFishGroup(req);
        }
    };

    const handleCreateFishGroup = async (req) => {
        req.actionDate.startOf('day');

        req.penNumber = penNumber.current;
        req.organizationId = organizationId.current;

        try {
            await dispatch(addFishGroup(req)).unwrap();

            alertSuccessMessage(t(SUCCESSFULLY_CREATION_MESSAGE));
            onReload();
            handleCancel();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleUpdateFishGroup = async (req) => {
        try {
            await dispatch(updateFishGroup({ id: fishGroup.id, item: req })).unwrap();

            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onReload();
            handleCancel();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    const handlePenChange = (penId, pen) => {
        form.setFieldValue('localityNumber', pen?.localityNumber);

        penNumber.current = pen?.penNumber;
        organizationId.current = pen?.organizationId;
    };

    return (
        <Modal
            title={
                isUpdateForm
                    ? t('fishGroup.upsertGroupForm.editFishGroup')
                    : t('fishGroup.upsertGroupForm.createFishGroup')
            }
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        onClick={form.submit}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {isUpdateForm ? t('general.form.saveChange') : t('general.form.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Button
                    type="dashed"
                    shape="round"
                    hidden={isUpdateForm}
                    onClick={() =>
                        form.setFields([
                            {
                                name: 'fishGroupId',
                                value: generateRandomFishGroupId(),
                                errors: null
                            }
                        ])
                    }
                    size="small"
                    style={{ left: '50%', transform: 'translate(-50%, -50%)' }}
                    icon={<SyncOutlined />}
                >
                    {t('fishGroup.upsertGroupForm.randomFishGroupId')}
                </Button>

                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={upsertFishGroup}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('fishGroup.upsertGroupForm.fishGroupId.title')}
                        name="fishGroupId"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.upsertGroupForm.fishGroupId.required')
                            },
                            {
                                pattern: XRegExp('^\\p{L}{3}\\d{3}$'),
                                message: t(
                                    'fishGroup.upsertGroupForm.fishGroupId.invalidFishGroupId'
                                )
                            }
                        ]}
                        hidden={isUpdateForm}
                    >
                        <Input
                            placeholder={t('fishGroup.upsertGroupForm.fishGroupId.placeholder')}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('fishGroup.actionDate.title')}
                        name="actionDate"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.actionDate.required')
                            }
                        ]}
                        hidden={isUpdateForm}
                    >
                        <DatePicker placeholder={t('fishGroup.actionDate.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('fishGroup.upsertGroupForm.typeOfFish.title')}
                        name="typeOfFish"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.upsertGroupForm.typeOfFish.required')
                            }
                        ]}
                        hidden={isUpdateForm}
                    >
                        <FDSelection
                            placeholder={t('fishGroup.upsertGroupForm.typeOfFish.placeholder')}
                            listSelectItem={PRODUCTION_FISH_NAMES.map((type) => ({
                                ...type,
                                text: t(type.text)
                            }))}
                        />
                    </Form.Item>

                    <Form.Item label={t('fishGroup.totalFishCount.title')} name="totalFishCount">
                        <InputNumber
                            className="w-full"
                            placeholder={t('fishGroup.totalFishCount.placeholder')}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('fishGroup.upsertGroupForm.site.title')}
                        name="localityNumber"
                        hidden={isUpdateForm}
                    >
                        <FDSelection
                            placeholder={t('fishGroup.upsertGroupForm.site.placeholder')}
                            listSelectItem={sitesSelection}
                            onChange={() => form.setFieldValue('penId', null)}
                            allowClear={true}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('fishGroup.pen.title')}
                        name="penId"
                        rules={[
                            {
                                required: true,
                                message: t('fishGroup.pen.required')
                            }
                        ]}
                        hidden={isUpdateForm}
                    >
                        <FDSelection
                            placeholder={t('fishGroup.pen.placeholder')}
                            listSelectItem={pensSelection.filter(
                                (pen) =>
                                    !pen.hasFishGroup &&
                                    (!localityNumber || pen.localityNumber === localityNumber)
                            )}
                            onChange={handlePenChange}
                            allowClear={true}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

UpsertGroupForm.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default UpsertGroupForm;
