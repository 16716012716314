import {
    DeleteOutlined,
    ForwardOutlined,
    GlobalOutlined,
    HistoryOutlined,
    LogoutOutlined,
    PartitionOutlined,
    PlusOutlined,
    RestOutlined
} from '@ant-design/icons';
import { Button, Col, Row, Space, Spin, Tabs } from 'antd';
import { FDTable } from 'common/components';
import {
    BLUE,
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    BUTTON_RED_CLASS,
    DATE_TIME_FORMAT,
    FISH_GROUP_ACTION,
    FISH_GROUP_STATUS,
    LIGHT_GRAY,
    SUCCESSFULLY_DELETION_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, formatDateTime } from 'common/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteFishGroup,
    getFishGroups,
    getPensSelection,
    getSitesSelection,
    getStockCount
} from 'redux/thunks';
import Swal from 'sweetalert2';
import DivideGroupForm from './DivideGroupForm';
import HistoryTable from './HistoryTable';
import MoveGroupForm from './MoveGroupForm';
import ReleaseGroupForm from './ReleaseGroupForm';
import UpsertGroupForm from './UpsertGroupForm';

const FishGroup = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: fishGroups, loading } = useSelector((s) => s.fishGroup);

    const [fishGroupType, setFishGroupType] = useState(FISH_GROUP_STATUS.ACTIVE);

    const [modalState, setModalState] = useState({
        type: FISH_GROUP_ACTION.CLOSE,
        item: null
    });

    const [historyModalState, setHistoryModalState] = useState({
        open: false,
        data: null
    });

    const columns = [
        {
            dataIndex: 'fishGroupId',
            title: 'fishGroup.table.fishwellFishId',
            sorter: {
                compare: (a, b) =>
                    `${a.fishGroupId}-${a.sibling}`.localeCompare(`${b.fishGroupId}-${b.sibling}`),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend'],
            render: (fishGroupId, record) => `${fishGroupId}-${record.sibling}`
        },
        {
            dataIndex: 'siteName',
            title: 'fishGroup.table.siteName',
            sorter: {
                compare: (a, b) => a.siteName.localeCompare(b.siteName),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'penNumber',
            title: 'fishGroup.table.penNumber',
            sorter: {
                compare: (a, b) => a.penNumber.localeCompare(b.penNumber),
                multiple: 3
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'actionDate',
            title: 'fishGroup.table.actionDate',
            sorter: {
                compare: (a, b) => moment(a.actionDate) - moment(b.actionDate),
                multiple: 4
            },
            render: (actionDate) => formatDateTime(actionDate, DATE_TIME_FORMAT),
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'typeOfFish',
            title: 'fishGroup.table.typeOfFish',
            render: (typeOfFish) => typeOfFish && t(`general.fish.${typeOfFish}`)
        },
        {
            dataIndex: 'totalFishCount',
            title: 'fishGroup.table.totalFishCount'
        },
        {
            title: 'fishGroup.table.action',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <>
                        <Button
                            key={`history-${record.id}`}
                            className={BUTTON_GRAY_CLASS}
                            onClick={() =>
                                setHistoryModalState({
                                    open: true,
                                    data: record.histories
                                })
                            }
                        >
                            <HistoryOutlined />
                            <span>{t('fishGroup.action.history')}</span>
                        </Button>

                        {fishGroupType === FISH_GROUP_STATUS.ACTIVE && (
                            <>
                                <Button
                                    key={`move-${record.id}`}
                                    className={BUTTON_GREEN_CLASS}
                                    onClick={() => handleMoveFFI(record)}
                                >
                                    <ForwardOutlined />
                                    <span>{t('fishGroup.action.move')}</span>
                                </Button>

                                <Button
                                    key={`divide-${record.id}`}
                                    className={BUTTON_GREEN_CLASS}
                                    onClick={() => handleDivideFFI(record)}
                                    disabled={record.sibling.indexOf('0') < 0}
                                >
                                    <PartitionOutlined />
                                    <span>{t('fishGroup.action.divide')}</span>
                                </Button>

                                <Button
                                    key={`release-${record.id}`}
                                    className="bg-sky-700 hover:bg-sky-800 focus-within:bg-sky-700 hover:text-white text-white focus-within:text-white font-semibold py-1 px-2 rounded border-none flex items-center justify-center"
                                    onClick={() =>
                                        setModalState({
                                            type: FISH_GROUP_ACTION.RELEASE,
                                            item: record
                                        })
                                    }
                                >
                                    <LogoutOutlined />
                                    <span>{t('fishGroup.action.release')}</span>
                                </Button>

                                <Button
                                    key={`delete-${record.id}`}
                                    className={BUTTON_RED_CLASS}
                                    onClick={() => handleDeleteFishGroup(record.id)}
                                >
                                    <DeleteOutlined />
                                    <span>{t('general.action.delete')}</span>
                                </Button>
                            </>
                        )}

                        {fishGroupType === FISH_GROUP_STATUS.RELEASED && (
                            <Button
                                key={`cancel-release-${record.id}`}
                                className={BUTTON_RED_CLASS}
                                onClick={() => handleDeleteFishGroup(record.id)}
                            >
                                <DeleteOutlined />
                                <span>{t('fishGroup.action.cancelRelease')}</span>
                            </Button>
                        )}
                    </>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useEffect(() => {
        dispatch(getFishGroups({ fishGroupType }));
    }, [fishGroupType]);

    const handleMoveFFI = (item) => {
        setModalState({ type: FISH_GROUP_ACTION.MOVE, item });

        dispatch(getStockCount(item.lTreePath));
    };

    const handleDivideFFI = (item) => {
        setModalState({ type: FISH_GROUP_ACTION.DIVIDE, item });

        dispatch(getStockCount(item.lTreePath));
    };

    const handleReload = () => {
        dispatch(getFishGroups({ fishGroupType }));
        dispatch(getPensSelection());
    };

    const handleClose = () => {
        setModalState({
            type: FISH_GROUP_ACTION.CLOSE,
            item: null
        });
    };

    const handleDeleteFishGroup = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.deleteModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteFishGroup(id)).unwrap();

                    handleReload();
                    alertSuccessMessage(t(SUCCESSFULLY_DELETION_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });
    };

    return (
        <>
            <h2 className="mb-2">{t('fishGroup.title')}</h2>
            <Row justify="center">
                <Col span={24}>
                    <Button
                        className={BUTTON_BLUE_CLASS}
                        onClick={() =>
                            setModalState({
                                type: FISH_GROUP_ACTION.CREATE,
                                item: null
                            })
                        }
                    >
                        <PlusOutlined />
                        <span>{t('fishGroup.newGroup')}</span>
                    </Button>

                    <Spin spinning={loading}>
                        <Tabs
                            activeKey={fishGroupType}
                            animated={true}
                            onChange={(value) => setFishGroupType(value)}
                            items={[
                                {
                                    label: (
                                        <span>
                                            <GlobalOutlined />
                                            <span>{t('fishGroup.activeGroups')}</span>
                                        </span>
                                    ),
                                    key: FISH_GROUP_STATUS.ACTIVE,
                                    children: (
                                        <FDTable headers={columns} data={fishGroups} rowKey="id" />
                                    )
                                },
                                {
                                    label: (
                                        <span>
                                            <RestOutlined />
                                            <span>{t('fishGroup.releasedGroups')}</span>
                                        </span>
                                    ),
                                    key: FISH_GROUP_STATUS.RELEASED,
                                    children: (
                                        <FDTable headers={columns} data={fishGroups} rowKey="id" />
                                    )
                                }
                            ]}
                        />
                    </Spin>
                </Col>
            </Row>

            <HistoryTable
                open={historyModalState.open}
                data={historyModalState.data}
                onClose={() =>
                    setHistoryModalState({
                        open: false,
                        data: null
                    })
                }
                fishGroupType={fishGroupType}
            />

            <UpsertGroupForm
                open={
                    modalState.type === FISH_GROUP_ACTION.CREATE ||
                    modalState.type === FISH_GROUP_ACTION.EDIT
                }
                fishGroup={modalState.item}
                onClose={handleClose}
                onReload={handleReload}
            />

            <MoveGroupForm
                open={modalState.type === FISH_GROUP_ACTION.MOVE}
                fishGroup={modalState.item}
                onClose={handleClose}
                onReload={handleReload}
            />

            <DivideGroupForm
                open={modalState.type === FISH_GROUP_ACTION.DIVIDE}
                fishGroup={modalState.item}
                onClose={handleClose}
                onReload={handleReload}
            />

            <ReleaseGroupForm
                open={modalState.type === FISH_GROUP_ACTION.RELEASE}
                fishGroup={modalState.item}
                onClose={handleClose}
                onReload={handleReload}
            />
        </>
    );
};

export default FishGroup;
