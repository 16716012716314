import axios from 'axios';
import {
    BASE_API_URL,
    FORBIDDEN_PAGE,
    FORBIDDEN_RESPONSE_STATUS_CODE,
    SESSION_EXPIRED_PAGE,
    UNAUTHORIZED_RESPONSE_STATUS_CODE
} from 'common/constants';
import get from 'lodash/get';

import qs from 'qs';
import { getToken } from './Auth';
import { appInsights } from 'AppInsights';

const instance = axios.create({
    timeout: 100000,
    maxContentLength: 10000
});

const defaultOptions = {
    headers: {
        accept: 'application/json',
        contentType: 'application/json',
        Authorization: '',
        'Global-Tenant-Id': ''
    }
};

const fileExportOptions = {
    headers: {
        'Content-Type': 'blob',
        Authorization: ''
    },
    responseType: 'arraybuffer'
};

const _get = (url, params = {}, options = {}) => {
    return instance.get(BASE_API_URL + url, {
        ...defaultOptions,
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const _export = (url, params = {}, options = {}) => {
    return instance.get(BASE_API_URL + url, {
        ...fileExportOptions,
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const post = (url, body = {}, options = {}) =>
    instance.post(BASE_API_URL + url, body, { ...defaultOptions, ...options });
const put = (url, body = {}, options = {}) =>
    instance.put(BASE_API_URL + url, body, { ...defaultOptions, ...options });
const patch = (url, body = {}, options = {}) =>
    instance.patch(BASE_API_URL + url, body, { ...defaultOptions, ...options });
const _delete = (url, options = {}) =>
    instance.delete(BASE_API_URL + url, { ...defaultOptions, ...options });

const interceptorHandleRequest = async (config) => {
    const token = await getToken();
    const tenantId = localStorage.getItem('tenantId') || '';
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        'Global-Tenant-Id': tenantId
    };
    config.headers.Authorization = `Bearer ${token}`;
    return config;
};

const interceptorHandleResponse = (response) => response;
const handleError = (error) => {
    const { response } = error;

    switch (response.status) {
        case UNAUTHORIZED_RESPONSE_STATUS_CODE:
            window.location.href = SESSION_EXPIRED_PAGE;
            return;
        case FORBIDDEN_RESPONSE_STATUS_CODE:
            window.location.href = FORBIDDEN_PAGE;
            return;
        default:
            break;
    }

    appInsights.trackException({ exception: error });
    return Promise.reject(get(error, 'response.data.errors') || get(error, 'response.data'));
};

instance.interceptors.request.use(interceptorHandleRequest, handleError);
instance.interceptors.response.use(interceptorHandleResponse, handleError);

export { _delete as delete, _export as export, _get as get, patch, post, put };
