import { EditOutlined } from '@ant-design/icons';
import { Button, InputNumber, Modal, Space } from 'antd';
import { FDTable } from 'common/components';
import { BUTTON_GRAY_CLASS, DATE_TIME_FORMAT, SUCCESSFULLY_EDIT_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, formatDateTime } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateFishGroup, getFishGroups } from 'redux/thunks';

const HistoryTable = ({ open, data, fishGroupType, onClose = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [totalFishCount, setTotalFishCount] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const totalFishCountObj = {};
        data?.forEach((item) => {
            totalFishCountObj[item.id] = item.totalFishCount;
        });
        setTotalFishCount(totalFishCountObj);
    }, [data]);

    const handleSaveChange = async (id) => {
        setLoading(true);
        try {
            await dispatch(
                updateFishGroup({ id, item: { totalFishCount: totalFishCount[id] } })
            ).unwrap();

            dispatch(getFishGroups({ fishGroupType }));

            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
        } catch (err) {
            alertErrorMessage(err, t);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            dataIndex: 'actionDate',
            title: 'fishGroup.table.actionDate',
            render: (actionDate) => formatDateTime(actionDate, DATE_TIME_FORMAT)
        },
        {
            dataIndex: 'status',
            title: 'fishGroup.historyTable.status'
        },
        {
            dataIndex: 'fishGroupId',
            title: 'fishGroup.table.fishwellFishId',
            render: (fishGroupId, record) => `${fishGroupId}-${record.sibling}`
        },
        {
            dataIndex: 'siteName',
            title: 'fishGroup.table.siteName'
        },
        {
            dataIndex: 'penNumber',
            title: 'fishGroup.table.penNumber'
        },
        {
            dataIndex: 'totalFishCount',
            title: 'fishGroup.table.totalFishCount',
            render: (_, record) => (
                <InputNumber
                    className="w-full"
                    placeholder={t('fishGroup.totalFishCount.placeholder')}
                    value={totalFishCount[record.id]}
                    onChange={(value) =>
                        setTotalFishCount({ ...totalFishCount, [record.id]: value })
                    }
                />
            )
        },
        {
            title: 'fishGroup.table.action',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={BUTTON_GRAY_CLASS}
                        disabled={totalFishCount[record.id] === record.totalFishCount}
                        onClick={() => handleSaveChange(record.id)}
                    >
                        <EditOutlined />
                        <span>{t('general.form.saveChange')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <Modal
            title={t('fishGroup.historyTable.title')}
            width={1000}
            open={open}
            onCancel={onClose}
            footer={
                <Button type="default" onClick={onClose} className="m-1">
                    {t('general.action.close')}
                </Button>
            }
        >
            <FDTable headers={columns} data={data} loading={loading} rowKey="id" />
        </Modal>
    );
};

HistoryTable.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.array,
    onClose: PropTypes.func
};

export default HistoryTable;
